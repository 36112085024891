import React from "react";
import {
    MDBBadge,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardImage,
    MDBCardText,
    MDBCardTitle,
    MDBIcon,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink
} from "mdb-react-ui-kit";

type ProyectProps = {
    repositoryUrl?: string,
    webUrl?: string,
    title: string,
    description: string,
    finished: boolean
    imageUrl?: string
}

export function Proyect(props: ProyectProps) {
    const description = props.description.split('\n').map(str => <MDBCardText>{str}</MDBCardText>);

    return (
        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBTabs className='card-header-tabs'>
                    <MDBTabsItem>
                        <MDBTabsLink
                            className={props.repositoryUrl === undefined ? "disabled" : ""}
                            href={props.repositoryUrl}
                            target="_blank"
                        >
                            Ver código
                            <MDBIcon className="ms-2" fas icon="code"/>
                        </MDBTabsLink>
                    </MDBTabsItem>
                    {
                        props.webUrl ?
                            <MDBTabsItem>
                                <MDBTabsLink href={props.webUrl} target="_blank">
                                    Ver web
                                </MDBTabsLink>
                            </MDBTabsItem>
                            : null
                    }
                </MDBTabs>
            </MDBCardHeader>
            {
                props.imageUrl ?
                    <MDBCardImage
                        src={props.imageUrl}
                        alt='...'
                    />
                    : null
            }
            <MDBCardBody>
                <MDBCardTitle className="main-title primary"><span>{props.title}</span></MDBCardTitle>
                { description }
                {
                    !props.finished ?
                        <MDBBadge className="text-dark" pill color='warning'>
                            En proceso
                        </MDBBadge>
                        :
                        <MDBBadge pill color='success'>
                            Finalizado
                        </MDBBadge>
                }
            </MDBCardBody>
        </MDBCard>
    )
}