import React from "react";
import {MDBBtn, MDBContainer, MDBInput} from "mdb-react-ui-kit";

export function Contact() {
    return (
        <MDBContainer className="content-container">
            <h1 className="mb-3">Contacta conmigo</h1>
            <form target="_blank" action="https://formsubmit.co/6e57637bae3208d9d41e6c8999019f6f" method="POST">
                <MDBInput label='Nombre' name="name" type='text' className="mb-2" />
                <MDBInput label='Mensaje' name="message" textarea rows={6} className="mb-2" />
                <MDBBtn type="submit">Enviar</MDBBtn>
            </form>
        </MDBContainer>

    )
}