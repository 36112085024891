import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Home} from "./pages/Home";
import {Proyects} from "./pages/Proyects";
import {Header} from "./components/navigation/Header";
import {Breadcrumb} from "./components/navigation/Breadcrumb";
import {Footer} from "./components/navigation/Footer";
import {Contact} from "./pages/Contact";

function App() {
    return (
        <>
            <Switch>
                <Route exact path="/">
                    <Header/>
                    <Home/>
                </Route>
                <Route path="/proyectos">
                    <Breadcrumb locationName="Proyectos" />
                    <Proyects />
                </Route>
                <Route path="/contacto">
                    <Breadcrumb locationName="Contacto" />
                    <Contact />
                </Route>
                <Route path="*">
                    <Breadcrumb locationName="Error 404" />
                    <h1>Error 404</h1>
                </Route>
            </Switch>
            <Footer />
        </>
    );
}

export default App;
