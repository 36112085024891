import React from "react";
import {MDBBtn} from "mdb-react-ui-kit";
import {Navbar} from "./Navbar";
import {Link} from "react-router-dom";

export function Header() {
    return (
        <header>
            <Navbar/>

            <div className="personal-container">
                <h1 className='mb-3 main-title cyan'>Hola, soy <span>David González García</span></h1>
                <h4 className='mb-3'>Estudiante de Ingeniería del Software</h4>
                <Link to="/proyectos">
                    <MDBBtn color="light">
                        Ver proyectos
                    </MDBBtn>
                </Link>
            </div>
        </header>
    )
}