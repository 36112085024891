import React from "react";
import {MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import {Proyect} from "../components/Proyect";

export function Proyects() {
    return (
        <MDBContainer className="content-container">
            <h1>Proyectos</h1>
            <MDBRow>
                <MDBCol md="4">
                    <Proyect
                        repositoryUrl="https://github.com/davigon/davigon"
                        webUrl="https://davigon.vercel.app/"
                        title="Portfolio"
                        description="
                            Web creada con React y MDBootstrap para mostrar mis proyectos.
                        "
                        finished={false}
                    />
                </MDBCol>
                <MDBCol md="4">
                    <Proyect
                        title="MusicApp"
                        description={
                            "App web para buscar y escuchar música, además de guardarla y añadirla " +
                            "a listas de reproducción, todo ello con manejo de usuarios. \nCreada " +
                            "con React y MDBootstrap en el frontend, y Spring Boot en el backend, " +
                            "con base de datos MySQL."
                        }
                        finished={false}
                    />
                </MDBCol>
            </MDBRow>
        </MDBContainer>
)
}