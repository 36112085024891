import React from "react";
import {MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import {WebLink} from "../components/WebLink";

export function Home() {
    return (
        <MDBContainer className="content-container">
            <MDBRow>
                <MDBCol md="8">
                    <p>
                        Principalmente utilizo <b>Java</b>, pero no tengo problema con otros
                        lenguajes como <b>JavaScript</b> y <b>TypeScript</b>. He realizado
                        proyectos completos que han involucrado a los lenguajes anteriores y al
                        manejo de bases de datos relacionales con <b>MySQL</b>.
                    </p>
                    <p>
                        En el <b>backend</b> he creado diversas API REST tanto con Spring Boot como
                        con Express.js, sirviendo desde bases de datos propias o consumiendo otras API.
                    </p>
                    <p>
                        En el <b>frontend</b> he utilizado React junto a MDBootstrap para mostrar los
                        datos de mis aplicaciones web.
                    </p>
                </MDBCol>
                <MDBCol md="4">
                    <p>
                        Puedes ver mi trabajo tanto en <WebLink href="https://github.com/davigon" newTab>GitHub </WebLink>
                        como en mi <WebLink href="/proyectos">página de proyectos</WebLink>.
                    </p>
                    <p>
                        Además, tienes la posibilidad de <WebLink href="/contacto">contactar conmigo</WebLink>.
                    </p>
                </MDBCol>
            </MDBRow>

        </MDBContainer>
    )
}