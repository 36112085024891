import React from "react";
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import logo from "../../images/logo96.png";

type BreadcrumbProps = {
    locationName: string
}

export function Breadcrumb(props: BreadcrumbProps) {
    return (
        <MDBNavbar expand="lg" light bgColor="light">
            <MDBContainer>
                <MDBNavbarBrand>
                    <img
                        src={logo}
                        height={30}
                        alt="logo"
                    />
                </MDBNavbarBrand>

                <MDBBreadcrumb>
                    <MDBBreadcrumbItem>
                        <Link to="/" className="text-primary">
                            Inicio
                        </Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>{props.locationName}</MDBBreadcrumbItem>
                </MDBBreadcrumb>
            </MDBContainer>
        </MDBNavbar>
    )
}