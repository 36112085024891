import React from "react";
import { Link } from "react-router-dom";

type WebLinkProps = {
    href: string,
    newTab?: boolean,
    children: string
}

export function WebLink(props: WebLinkProps) {

    if (props.newTab)
        return (
            <a href={props.href} target="_blank" rel="noreferrer" className="link-primary">{props.children}</a>
        )

    return (
        <Link to={props.href} className="link-primary">{props.children}</Link>
    )
}