import React, {useState} from "react";
import {
    MDBCollapse,
    MDBContainer,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarItem,
    MDBNavbarNav,
    MDBNavbarToggler
} from "mdb-react-ui-kit";
import {Link} from "react-router-dom";
import logo from "../../images/logo96.png";

export function Navbar() {
    const [showNavbarItems, setShowNavbarItems] = useState(false);

    return (
        <MDBNavbar expand='lg' light bgColor="light">
            <MDBContainer>
                <MDBNavbarBrand>
                    <img
                        src={logo}
                        height={30}
                        alt="logo"
                    />
                </MDBNavbarBrand>

                <MDBNavbarToggler
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                    onClick={() => setShowNavbarItems(!showNavbarItems)}
                >
                    <MDBIcon icon='bars' fas/>
                </MDBNavbarToggler>

                <MDBCollapse navbar show={showNavbarItems}>
                    <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
                        <MDBNavbarItem>
                            <Link className="nav-link active"
                                  to="/"
                                  onClick={() => showNavbarItems ? setShowNavbarItems(false) : null}
                            >
                                Inicio
                            </Link>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <Link className="nav-link"
                                  to="/proyectos"
                                  onClick={() => showNavbarItems ? setShowNavbarItems(false) : null}
                            >
                                Proyectos
                            </Link>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <Link className="nav-link"
                                  to="/contacto"
                                  onClick={() => showNavbarItems ? setShowNavbarItems(false) : null}
                            >
                                Contacto
                            </Link>
                        </MDBNavbarItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBContainer>
        </MDBNavbar>
    );
}