import React from "react";
import {MDBContainer, MDBFooter} from "mdb-react-ui-kit";

export function Footer() {
    return (
        <>
            <div id="spacer" />
            <MDBFooter className="font-small mt-3">
                <div className="footer-copyright text-white text-center py-3"
                     style={{ backgroundColor: "#123ff1" }}>
                    <MDBContainer fluid>
                        {new Date().getFullYear() + "."} Portfolio de David González García
                    </MDBContainer>
                </div>
            </MDBFooter>
        </>
    )
}